<!-- Finder de Tipos de Modalidad -->

<template>
  <div class="modalidades_F" v-if="schema">
    <div class="conflex" style="justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera" v-if="Entorno.header.header">       
          <base_Header 
            :Entorno="Entorno.header"
            @onEvent="event_Header">          
          </base_Header>
        </div>

        <!-- Contenido -->     
        <div class="conflex">
    
          <!-- Grid -->
          <base_Fgrid 
            style="width:850px"
            :padre="stName" 
            :Entorno="Entorno" 
            show-expand
            :items-per-page=-1 
            @onEvent="event_Grid">         

            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{ Sitem }">    
            
              <div style="padding:10px 0 0 10px;">
                <btracombi                      
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="top"
                  @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
                </btracombi>
              </div>
            </template>

            <!-- Prima única -->
            <template v-slot:pun="{ Sitem }">  
              <v-icon v-if="Sitem.pun=='1'" small style="color:green">{{ 'mdi-check' }}</v-icon>
              <v-icon v-else small style="color:red">{{ 'mdi-cancel' }}</v-icon>
            </template>

            <!-- Slot panel expansible -->
            <template v-slot:expansible_ctrls="{}">             
              <modalidades_XM
                :padre="stName"
                :accion="accion"
                :accionRow="rowSelected"               
                :disparo="disparo"            
                @onEvent="event_expansible">           
              </modalidades_XM>
            </template>
          </base_Fgrid>
        </div>
      </v-sheet>  
    </div>    
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const modalidades_XM = () => plugs.groute("modalidades_XM.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Fgrid, base_Header, btracombi, modalidades_XM },
    props: {
      padre: { type:String, default: ''},     
      componenteTipo: { type:String, default:'FM' },      
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return { 
        schema:null,             
        api:'modalidades_F',
        stName:'stFtipo_modalidad',
        disparo:false,
        accion:'0',
             
        headers: {},        
      };
    },

    methods: {
      ini_data() {      
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;
    
        // entorno
        this.Entorno.grid.autoload= true;
        this.Entorno.header.titulo= 'Modalidades Pólizas Mapfre';
        this.Entorno.header.header= true;
        this.Entorno.grid.row.expansible= true;
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.FM));  
        
        this.headers = {
          header:[          
            { text: "Nombre", value: "name", witdh: "70%" },
            { text: "Prima Única", value: "pun", witdh: "20%", slot:true },         
          ]
        };

        this.Entorno.grid.headers = this.set_headers();      
      },

    
    }
  };
</script>


<!-- <script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import { mixinMto_inRow } from "@/mixins/mixinMto_inRow.js";
  import plugs from "@/common/general_plugs";
  const base_Fedit = () => plugs.groute("base_Fedit.vue", "base");

  export default {
    mixins: [mixinFinder,mixinMto_inRow],
    components: { base_Fedit },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: null,
        api:'tipo_modalidad_F',
        stName:'stFtipo_modalidad',
        headers: {},
       
      };
    },

    methods: {

     
      event_capture(evt) 
      {
        console.log("*** onEvent productos.vue. Accion: ", evt, " ***");
        this.acciones_Mto_inRow(evt);
      },

     
      ini_data() 
      {
        console.log("DEV " + this.api + " ********************** (ini_data)");

      
        this.schema = this.$store.state[this.stName].schema;

       
        this.Entorno.header.titulo = "Modalidades Mapfre";  

        this.headers = {
          header: [
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },
            { text: "Nombre", value: "name", witdh: "70%" },
            { text: "Pun", value: "pun", witdh: "20%" }, 
          ],
        };

        this.Entorno.grid.headers = this.set_headers();
      },


      get_records() 
      {
          this.Entorno.records.records = this.$store.getters.get_dato_inicial("productos").sort((a, b) => {
            return a.name < b.name ? -1 : 0;
          });
      }

    }
  };
</script> -->
